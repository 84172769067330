import { createApp } from "vue";
import App from "./App.vue";
import "@/assets/css/tailwind.css";
import router from "./router";
import axios from "axios";
import VueAxios from "vue-axios";
import { createMetaManager } from "vue-meta";
import Toaster from "@meforma/vue-toaster";

const ENVIRONMENT = process.env.VUE_APP_ENV;

const API = {
  hml: "x5uvpolq82",
  production: "blqnht5hdg",
  dev: "http://localhost:3001/api",
};

const REGION = {
  hml: "us-east-2",
  production: "sa-east-1",
  dev: "",
};

const START = {
  hml: "https://inicio.hml.bcompliance.com.br",
  dev: "https://inicio.hml.bcompliance.com.br",
  production: "https://inicio.bcompliance.com.br",
};

const CLIENT = {
  hml: "https://cliente.hml.bcompliance.com.br",
  dev: "https://cliente.hml.bcompliance.com.br",
  production: "https://cliente.bcompliance.com.br",
};

localStorage.setItem("counter", 0);
axios.interceptors.response.use(null, (error) => {
  /** @type {import("axios").AxiosRequestConfig} */

  let counter = parseInt(localStorage.getItem("counter"));
  const config = error.config;
  localStorage.setItem("counter", ++counter);
  if (counter < 2 && error.response.status >= 500) {
    return new Promise((resolve) => {
      resolve(axios(config));
    });
  } else {
    localStorage.setItem("counter", 0);
    return Promise.reject(error);
  }
  
});

const path =
  ENVIRONMENT === "dev"
    ? API[ENVIRONMENT]
    : `https://${API[ENVIRONMENT]}.execute-api.${REGION[ENVIRONMENT]}.amazonaws.com/production/api`;

axios.defaults.baseURL = path;

const app = createApp(App);

let VINDI_SANDBOX = "";
let VINDI_PUBLIC_KEY = "SzlUS3MtckpHSDA0amExWGcwT2xsME4yUWZlOVYtRmExU2xBY0Q0ZHR1ZzoK";
if (ENVIRONMENT === "hml" || ENVIRONMENT === "dev") {
  VINDI_SANDBOX = "sandbox-";
  VINDI_PUBLIC_KEY = "ZjlQZmFqUW9xTXcwMnZ2bWoxNDhZWjFKbU5rMXJaVjJzV3lEd0pnTnJxawo=";
}

const BASE_VINDI_URL = `https://${VINDI_SANDBOX}app.vindi.com/api/`;

app.config.globalProperties.BASE_VINDI_URL = BASE_VINDI_URL;
app.config.globalProperties.VINDI_PUBLIC_KEY = VINDI_PUBLIC_KEY;
app.config.globalProperties.clientPanel = CLIENT[ENVIRONMENT];
app.config.globalProperties.startPage = START[ENVIRONMENT]; // paginas de denúcia, sugestão e requisição dados
app.config.globalProperties.environment = ENVIRONMENT;

app.config.globalProperties.toast = (message, type, toast, timeuout = 3000) => {
  toast[type](message);
  setTimeout(toast.clear, timeuout);
};

app.config.globalProperties.$filters = {
  currencyBRL(value) {
    if (typeof value !== "number") {
      return value;
    }
    var formatter = new Intl.NumberFormat("pt-BR", {
      style: "currency",
      currency: "BRL",
    });
    return formatter.format(value);
  },
};

app.use(router);
const metaManager = createMetaManager();
app.use(metaManager);
app.use(VueAxios, axios);
app.use(Toaster);
app.mount("#app");
